<template>
  <div class="acn-specialist-job-detail">
    <div class="container fluid">
      <div class="d-flex">
        <!-- Application -->
        <div
          v-if="myApplication.length > 0"
          class="card d-inline-block p-4 mr-2 mb-2"
        >
          <h5 class="mb-1">Application Pending Approval</h5>
          <p>Your current application is pending for registrant's approval.</p>
          <fd-button
            class="bordered main mt-2"
            @click="$router.push({ name: 'ACNMyJobApplications' })"
          >
            My Job Applications
            <i class="fas fa-chevron-right ml-1"></i>
          </fd-button>
        </div>
        <div class="registrant card d-inline-block p-4 mb-2">
          <h4 class="mb-3">Registrant</h4>
          <JobHandler
            :user="_.get(job, 'acnQuest.listing.property.agencyUser')"
            :getUser="(val) => val.user"
            :getAgent="(val) => val"
          ></JobHandler>
        </div>
      </div>
      <PropertyDetail
        class="card p-2 md-p-3"
        :propertyDetails="_.get(job, 'acnQuest.listing.property')"
        :canEdit="false"
        :backNav="false"
      ></PropertyDetail>
      <fd-button
        v-if="canApply"
        class="main medium fixed round"
        icon="fas fa-file-pen"
        @click="applyForSpecialist"
      >
        Apply For Specialist
      </fd-button>
    </div>
  </div>
</template>

<script>
import PropertyDetail from "@/components/GlobalComponents/Shared/Property/PropertyDetail";
import JobHandler from "@/modules/ACN/components/JobHandler";
import { acn as acnAPI } from "@/api";

export default {
  components: {
    PropertyDetail,
    JobHandler
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      job: {},
      myApplication: []
    };
  },
  computed: {
    canApply() {
      let hasHandler = !!this.job.user;
      let hasApplied = this.myApplication.length > 0;

      return !hasHandler && !hasApplied;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getJob();
      await this.checkIsForSpecialist();
      this.getMyApplication();
    },
    checkIsForSpecialist() {
      if (!this.job.isForSpecialist) {
        this.$router.push({ name: "NotFound" });
      }
    },
    async getJob() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await acnAPI.getJob(this.$route.params.id);
        this.job = this._.cloneDeep(data);

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch job data. Please try again later."
        });
      }
    },
    async getMyApplication() {
      try {
        this.$store.commit("setIsLoading", true);
        let res = await acnAPI.getMyJobApplications({
          queries: {
            "acnReward:id": this.job.id
          }
        });
        this.myApplication = res.data;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch application data. Please try again later."
        });
      }
    },
    async applyForSpecialist() {
      let confirm = await this.$confirm({
        title: "Apply For Specialist",
        message: `Are you sure you want to submit an application of specialist?`,
        confirmText: "Apply"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.applyJob(this.$route.params.id);

          this.$store.commit("setIsLoading", false);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Applied",
            text:
              "Application has been applied, awaiting registrant's approval."
          });

          this.$router.push({ name: "ACNMyJobApplications" });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to apply for specialist. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
